import React from "react"
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EmailIcon from 'mdi-material-ui/EmailOutline';
import PhoneIcon from 'mdi-material-ui/Phone';
import MapIcon from 'mdi-material-ui/MapMarker';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Layout from "../components/layout"
import SEO from "../components/seo"
import classes from './home.module.css';

import cprImage from '../images/cpr.png';
import sd47Image from '../images/SD47.png';
import ubcImage from '../images/UBC.png';
import swanseaImage from '../images/swansea.png';
import titanImage from '../images/titan.png';
import xanatosImage from '../images/Xanatos.png';
import climateSmartImage from '../images/climate-smart.png';
import caprImage from '../images/capr.png';
// import pressImage from '../images/PRESS.png';
import speakoImage from '../images/speako.png';
import qcjLogoImage from '../images/qathet.svg';
import prwcImage from '../images/prwc.png';
import prfaiImage from '../images/prfai.png';
import miklatImage from '../images/miklat-logo.png';
import courtenayImage from '../images/courtenay.jpg';
import prcatImage from '../images/prcat.svg';
import sustainImage from '../images/sustainpr.png';
import liftImage from '../images/lift.png';

import screen1 from '../images/screenshots/phone-farmer.png';
import screen2 from '../images/screenshots/laptop-cpr.png';
import dataScreenshot from '../images/screenshots/capr.png';
import faceScreenshot from '../images/screenshots/face.png';
import dbLogos from '../images/screenshots/dbs.png';
import dbScreenshot from '../images/screenshots/db3.png';
import pcbScreenshot1 from '../images/screenshots/pcb1.png';
import pcbScreenshot2 from '../images/screenshots/pcb2.png';
import appsLogos from '../images/screenshots/apps.png';
import xanatosScreens from '../images/screenshots/xanatos-screenshots.png';
import cartScreenshot from '../images/screenshots/checkout.png';
import storeScreenshot from '../images/screenshots/store.png';
import slick1 from '../images/screenshots/slick1.png';
import slick2 from '../images/screenshots/slick2.png';

import ConsultingLogos from './consulting';

const Section = ({ className, children, fullpageApi, noArrow, light }) => (
  <div className={classNames(classes.section, className)}>
    {children}
  </div>
);

const Client = ({ className, children, image, url }) => (
  <Grid item xs={12} sm={6} md={4} className={classNames(classes.card, classes.className)} component={url ? 'a' : undefined} href={url} target={url && '_blank'}>
    <img src={image} className={classes.cardImage} alt="Client logo" />
    <Typography variant="body2">
      {children}
    </Typography>
  </Grid>
);

const Specialty = ({ children, icons, primary, secondary, screenshots, reverse, force2col }) => {
  const text = (
    <Grid item container xs={12} sm={6} justify="center" direction="column" alignItems="center" className={classes.specialtyText}>
      {/* <div className={classes.specialtyIcons}>
        {icons.map((Icon, i) => <Icon key={i} />)}
      </div> */}
      <Typography variant="h5" gutterBottom color="textPrimary" className={classes.specialtyPrimary}>{primary}</Typography>
      {secondary ? <Typography variant="body1" color="textSecondary" className={classes.specialtySecondary} align="left">{secondary}</Typography> : null}
    </Grid>
  );

  const pics = (
    <Grid item container xs={12} sm={6} alignItems="center" className={classes.specialtyScreenshots}>
      {screenshots.map((screenshot, i) => (
        <Grid item xs={(screenshots.length > 1) || force2col ? 6 : 12} key={i}>
          <img src={screenshot.src} alt="Screenshot" />
          <Typography variant="caption" align="center" dangerouslySetInnerHTML={{ __html: screenshot.caption }} color="textSecondary" />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container className={classes.specialty}>
      {reverse ? pics : text}
      {reverse ? text : pics}
    </Grid>
  );
}

// <Specialty icons={[AndroidIcon, AppleIcon]} primary="Android & iPhone apps" />

const specialties = [
  {
    primary: `Android & iPhone apps`,
    secondary: `
      We specialize in making Android and iPhone apps with complex functionality, interface with custom hardware,
      enhance business workflows, or for virually any other application.
    `,
    screenshots: [
      { caption: `Billable hours tracking business management app`, src: slick1 },
      { caption: `Forestry company workflow digitization app.`, src: slick2 },
    ],
    force2col: true,
  },
  {
    primary: `Online mapping, telemetry, and location intelligence`,
    secondary: `
      Need vehicle fleet tracking and telemetry? Or any interactive data-driven live online map? We do that.
      With experience from large-scale marine vesllel traffic control systems to small-scale oil and gas sector
      asset tracking and safety systems we deliver the mapping solutions you need.
    `,
    screenshots: [
      { caption: `Vessel traffic control and maritime surveillance`, src: xanatosScreens },
    ],
  },
  {
    primary: `Data analyicts & visualization`,
    secondary: (
      <>
        We give your data a voice by creating interactive online data analytics and visualization dashboards
        so you can gain insight, automate report generation, and share your story.
        <br /><br />
        For even more dynamic data needs, we have also been
        delivering <Link href="https://www.tableau.com/">Tableau™</Link> data dashboards and consulting for 6 years.
      </>
    ),
    screenshots: [
      { caption: `CO<sub>2</sub> emissions calculator and analyzer`, src: dataScreenshot },
      { caption: `University of Swansea neuro-psychology research`, src: faceScreenshot },
    ],
  },

  {
    primary: `Database design and repair`,
    secondary: `
      Is your database painful to use or losing data? Do you need a modernized user-friendly system? 
      We are experts in redeveloping databases and creating extremely user-friendly and efficient workflows
      that compliment your business.
      We take care of all data conversions and use industry standard high-secuirty measures.
    `,
    screenshots: [
      { caption: `We repair and upgrade any type of database`, src: dbLogos },
      { caption: `Provincial Resource Program database redevelopment`, src: dbScreenshot },
    ],
  },

  {
    primary: `E-Commerce`,
    secondary: (
      <>
        We provide the full spectrum of e-commerce development services: from fully custom online stores and marketplace
        development to <b>Shopify, Etsy, and WooCommerce</b> setup and consulting.
        <br /><br />
        After your online store is up and running, you need customers and we help with that too! We have 5 years experience running online marketing
        campaigns with Google, Facebook, and Instagram ads. We understand exactly how to connect your store and products to each company's
        ad algorithms to maximize your sales and minimize your costs.
      </>
    ),
    screenshots: [
      { caption: `Speako Technologies Corp. online store`, src: storeScreenshot },
      { caption: `Fully custom secure checkout experience`, src: cartScreenshot },
    ],
  },
  {
    primary: `Modern websites`,
    secondary: (
      <>
        We love making modern, clean, user-friendly websites that work on any device.
        Using the latest web technologies, our sites are fast, secure, and rich in advanced functionality.
        <br /><br />
        For web consulting and development we specialize in "full-stack" including front-end user-interface, secure data
        transfer protocols, APIs, databases, and cloud server administration and maintenance.
      </>
    ),
    screenshots: [
      { caption: `Farmers' Institute local food finder`, src: screen1 },
      { caption: `City of Powell River municipal site`, src: screen2 },
    ],
  },

  {
    primary: `Custom hardware design`,
    secondary: `
      Have a problem that can't be solved with software alone? We develop custom computer and electronics hardware.
      Previous projects include logging/excavator electronics, radar, personal electronics, and GPS mapping and telemetry.
    `,
    screenshots: [
      { caption: ``, src: pcbScreenshot1 },
      { caption: ``, src: pcbScreenshot2 },
    ],
  },



]

const clients = [
  { image: ubcImage, primary: `Neuro-psychology research & training software.` },
  { image: sd47Image, primary: `Secure database redevelopment & workflow enhancements` },
  { image: xanatosImage, primary: `Maritime surveillance, cartography, and vessle trafic control software.` },
  { image: climateSmartImage, primary: `Greenhouse gas emissions calculator & marketing` },
  { image: swanseaImage, primary: `Neuro-psychology rehabilitation software.` },
  // { image: caprImage, primary: `Easy, interactive greenhouse gas emissions footprint calculator` },
  // { image: qcjLogoImage, primary: `qathet Community Justice website` },
  { image: speakoImage, primary: `E-commerce store and backend inventory management system.` },
  { image: titanImage, primary: `Manufacturing workflow enhancements, radar software & hardware.` },
  // { image: pressImage, primary: 'Website' },
  { image: cprImage, primary: `Municipal website.` },
];

const localClients = [
  { image: cprImage, primary: `Powell River municipal website`, url: 'https://powellriver.ca/' },
  { image: prfaiImage, primary: `Powell River Farmer's Institute local food finder interactive map`, url: 'https://prfarmers.ca/' },
  { image: courtenayImage, primary: `Tie Dye legend Courtenay Pollock's online store and marketing campaign`, url: 'https://www.courtenaytiedye.com/' },
  { image: qcjLogoImage, primary: `qathet Community Justice website`, url: 'https://qathetcj.org/' },
  { image: miklatImage, primary: `Powell River Miklat Recovery House`, url: 'https://powellrivermiklat.com/' },
  { image: sd47Image, primary: `SD#47 Secure database redevelopment & workflow enhancements` },
  { image: prwcImage, primary: `Powell River Window Cleaning business site`, url: 'https://powellriverwindowcleaning.com/' },
  { image: prcatImage, primary: `Community Action Team`, url: 'https://prcat.ca/' },
  { image: sustainImage, primary: `Substance Users Society`, url: 'https://www.sustainpowellriver.ca/' },
];

const IndexPage = () => (
  <Layout homepage>
    <SEO title="Advanced Web Apps" keywords={[`web`, `developer`, 'development', 'design', `app`, 'website']} />

    {/* <Section className={classes.launchOnlineSection} >
      <div className={classes.launchOnline}>
        <Typography variant="h6" align="center" color="textPrimary" className={classes.subtitle} >Februrary, 2021:</Typography>
        <Typography variant="h4" gutterBottom align="center">Work With Us To Claim Your $7,500 E-Commerce Grant</Typography>
        <br />
        <Typography variant="body1">
          In Februrary, the BC Government announced the <b><Link href="https://launchonline.ca/">Launch Online Grant Program</Link></b>. This
          program pays 75% (up to $7,500) of the cost of setting up your small business online store, marketing campaigns, and website development and hosting. 
          From start to finish we will work with you to meet the grant criteria and maximize its benefit for your business.
          <br /><br />
          Grants cover development, consulting, advertisting, training, and ongoing maintenance. 
          We will work with you to:
          </Typography>
          <ul>
            <li>Develop and launch your online store</li>
            <li>Create or update your business website</li>
            <li>Set up your product inventory</li>
            <li>Perform Search Engine Optimization (SEO)</li>
            <li>Launch online marketing campaigns (Google, Facebook, & Instagram, ads)</li>
            <li>Setup your sales metrics tracking and online advertising return-on-investment analytics</li>
            <li>Train your staff on your new store and inventory management system</li>
            <li>Manage ongoing website and store hosting maintenance</li>
          </ul>
        <Typography variant="body1">
          Our online stores meet all of the criteria required by the grant. For details on if your business qualifies for the grant and to start
          the application, see the <Link href="https://launchonline.ca/" target="_blank">official Launch Online Grant Program website</Link>.
        </Typography>
        <br /><br />
        <Typography variant="h4" align="center" gutterBottom>
          Don't Delay - Applications are Limited
        </Typography>
          <br />
        <Typography variant="body1" >
          Funding is awarded on a first-come first-served basis and will end when the fund is depleted. <Link href="/#contact-us">Contact us</Link> today to get started.
          Quotes are free, firm, and zero obligation.
        </Typography>
        <br />
        <Button size="large" color="primary" variant="contained" href="/#contact-us" component="a">Get Started</Button>
      </div>
    </Section> */}


    <Section className={classes.specialtiesSection}>
      <Typography variant="h3" align="center" color="textSecondary" style={{ padding: `64px 16px 0 16px` }}>What We Do</Typography>
      <div className={classes.specialties}>
        {specialties.map((props, i) => (
          <Specialty key={i} reverse={i % 2 !== 0} {...props} />
        ))}
      </div>
    </Section>

    {/* <video width="250" autoPlay muted loop>
      <source src={dataVideo} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video> */}

    <Section className={classes.clients}>
      <a name="clients">
        <Typography variant="h2" gutterBottom align="left">
          Clients & Projects
        </Typography>
      </a>
      <Typography variant="body1" align="left">
        Some of our clients and projects include:
      </Typography>

      <Grid container spacing={40} className={classes.cards} justify="center">
        {clients.map(({ image, primary }, i) => <Client image={image} key={i}>{primary}</Client>)}
      </Grid>
    </Section>

    <Section className={classes.consulting}>
      <a name="consulting">
        <Typography variant="h2" gutterBottom align="left">
          Consulting
        </Typography>
      </a>

      <Typography variant="body1" align="left">
        Finding bugs in your existing system that need fixing? Need to tweak your setup or build a custom plugin?
        Or just need guidance with an RFP or advice on new project?
        We offer expert consulting in many web, software, and data analytics technologies.
        Our specialities include:
      </Typography>
      <br /><br /><br />
      <ConsultingLogos />
    </Section>

    <Section className={classes.nonprofit}>
      <a name="non-profits">
        <Typography variant="h4" gutterBottom align="left">
          Non-Profits & Pro Bono Work
        </Typography>
      </a>
      <Typography variant="body1" align="left">
        Are you a non-profit with a social justice, environmental, or community building mission?
        We want to make a difference too using the skills we have--technology--so we offer reduced
        rates or pro bono work to projects that excite us.
        If you have a project you think might fit, get in touch and we'll see what we can do.
      </Typography>

    </Section>

    <a name="contact-us">
      <Typography variant="h2" align="center" className={classes.contactTitle} gutterBottom>
        Have a project? Let's talk.
      </Typography>
    </a>
    <Section className={classes.contact}>
      <Grid container spacing={24} justify="center" alignItems="center">
        <Grid item container xs={12} md={6} style={{ padding: 32 }} direction="column" alignItems="flex-start">

          <Typography align="left" variant="body1" gutterBottom color="textPrimary" style={{ marginBottom: 24 }}>
            Have a project in mind? Let's talk about it.<br />Quotes and explorations are always free.
          </Typography>
          <Typography variant="body1" align="left" color="textPrimary" gutterBottom>
            Our quotes are always firm with no additional hours, extra fees, or unexpected charges
            unless you change the project.
          </Typography>

          <br />
          <Typography variant="body1" className={classes.contactInfo} style={{ lineHeight: 2 }} component="div">
            <Typography variant="h6" color="textSecondary">Websites, apps, databases & software development:</Typography>
            <Typography variant="body1" color="textPrimary">Noland Germain</Typography>
            <EmailIcon style={{ verticalAlign: 'middle', marginRight: 8 }} /><Link href="mailto:noland@advancedwebapps.ca">noland@advancedwebapps.ca</Link><br />
            <PhoneIcon style={{ verticalAlign: 'middle', marginRight: 8 }} /><Link href="tel:1-778-741-9875">778-741-9875</Link><br />
          </Typography>
          <br />
          <Typography variant="body1" className={classes.contactInfo} style={{ lineHeight: 2 }} component="div">
            <Typography variant="h6" color="textSecondary">Tableau, Salesforce, Excel, & data analytics:</Typography>
            <Typography variant="body1" color="textPrimary">Ana Lukyanova</Typography>
            <EmailIcon style={{ verticalAlign: 'middle', marginRight: 8 }} /><Link href="mailto:ana@advancedwebapps.ca">ana@advancedwebapps.ca</Link><br />
            <PhoneIcon style={{ verticalAlign: 'middle', marginRight: 8 }} /><Link href="tel:1-604-618-7227">604-618-7227</Link><br />
          </Typography>
        </Grid>

        <Grid item xs={12} md={6} justify="center" alignItems="center" direction="column" container>
          <iframe
            width="100%"
            height="450"
            frameBorder="0" style={{ border: 'none' }}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190308.8516832398!2d-124.63411994905567!3d49.828576755772055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5487e736aaad8b61%3A0x8815ca9e635b034!2sYaroshuk%20Rd%2C%20Powell%20River%2C%20BC!5e0!3m2!1sen!2sca!4v1567486201366!5m2!1sen!2sca"
            allowFullScreen
          />
          <Typography variant="body1" className={classes.contactInfo} style={{ lineHeight: 2 }} component="div">
            <MapIcon style={{ verticalAlign: 'middle', marginRight: 8 }} />7 - 3965 Yaroshuk Road<br />
            <span style={{ display: 'inline-block', marginRight: 32 }} />Powell River, BC, Canada<br />
          </Typography>
        </Grid>
      </Grid>
    </Section>
  </Layout >
)

export default IndexPage
